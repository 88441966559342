<template>
  <div>
    <PageHeader />
    <AgentForms :agent-id="agentId" />
  </div>
</template>

<script>

export default {
  page: {
    title: 'Agent detail',
  },
  components: {
    AgentForms: () => import('./forms.vue'),
  },
  computed: {
    agentId() {
      return this.$route.params.id === 'create' ? null : this.$route.params.id
    },
  },
}
</script>
